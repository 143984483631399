<template>
    <div class="white-container">
        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="showSnackbar"
                md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>



        <!-- FORGOT PASSWORD EMAIL FORM-->
        <div class="email-form" v-if="!recoverToken">
            <div class="forgotDialog">
                <md-dialog-title class="title" :class="{'blurItem': recoverPasswordDialogWait}">Find your account</md-dialog-title>
                <md-dialog-content class="content" :class="{'blurItem': recoverPasswordDialogWait}">
                    <div class="intro" :class="{'blurItem': recoverPasswordDialogWait}">Please enter your email to reset your password.</div>
                    <md-field>
                        <md-input placeholder="Email" v-model="recoverEmail" @keyup.enter="recoverPasswordFunction"></md-input>
                    </md-field>
                    <div v-show="errorMsg" class="md-error errorMsg">{{ errorMsg }}</div>
                    <md-dialog-actions class="dialog-actions" :class="{'blurItem': recoverPasswordDialogWait}">
                        <md-button class="md-primary close" :to="{name: 'auth.login'}">Cancel</md-button>
                        <md-button class="md-primary submit md-raised" @click="recoverPasswordFunction">Find account</md-button>
                    </md-dialog-actions>
                </md-dialog-content>
                <md-progress-spinner class="loader" md-mode="indeterminate" v-show="recoverPasswordDialogWait"></md-progress-spinner>
            </div>
        </div>


        <!-- RESET PASSWORD FORM -->
        <div class="password-form" v-if="recoverToken">
            <div class="forgotDialog">
                <md-dialog-title class="title" :class="{'blurItem': recoverPasswordDialogWait}">Find your account</md-dialog-title>
                <md-dialog-content class="content" :class="{'blurItem': recoverPasswordDialogWait}">
                    <div class="intro" :class="{'blurItem': recoverPasswordDialogWait}">Update your password</div>
                    <md-field>
                        <md-input placeholder="Enter your new password" v-model="recoverPassword" type="password"></md-input>
                    </md-field>
                    <md-field>
                        <md-input placeholder="Confirm your new password" v-model="recoverPasswordConfirm" type="password"></md-input>
                    </md-field>
                    <div v-show="errorMsg" class="md-error errorMsg extrapadding">{{ errorMsg }}</div>
                    <md-dialog-actions class="dialog-actions" :class="{'blurItem': recoverPasswordDialogWait}">
                        <md-button class="md-primary close" :to="{name: 'auth.login'}">Close</md-button>
                        <md-button class="md-primary submit md-raised" @click="recover()">Submit</md-button>
                    </md-dialog-actions>
                </md-dialog-content>
                <md-progress-spinner class="loaderLogin" md-mode="indeterminate" v-show="recoverPasswordDialogWait"></md-progress-spinner>
            </div>
        </div>
    
    </div>
    
</template>

<script>
    import config from '../config.js'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: "PasswordReset",
        data() {
            return {
                recoverEmail: null,
                recoverPasswordDialogWait: false,
                showSnackbar: false,
                snackMsg: '',
                recoverToken: false,
                errorMsg: null,
                recoverPassword: null,
                recoverPasswordConfirm: null,
                user: []
            }
        },
        created(){
            const recover = this.$route.query.recover;
            if(recover){
                this.recoverToken = recover;
            }
        },
        methods: {
            async recoverPasswordFunction(){
                this.recoverPasswordDialogWait = true

                if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.recoverEmail)){
                    this.showSnackbar = false
                    this.snackMsg = ''
                    this.user = this.$store.getters.user

                    let response = await this.$store.dispatch('RecoverEmail', {
                      email: this.recoverEmail,
                    })

                    this.showSnackbar = true
                    this.snackMsg = "Please check your email for recovery instructions"
                    this.recoverPasswordDialogWait = false
                    this.$router.push({name: 'auth.login', query: {notify: 'sent'}});

                }else{
                    this.errorMsg = "Please enter a valid email address."
                    //this.showSnackbar = true
                    this.recoverPasswordDialogWait = false
                }

            },

            async verify(verified_token, id, token){
                this.sending = true
                this.loginError = false
                const data = {
                    "data": {
                        'id': id,
                        'attributes': {
                            "status": 2,
                            "verified_token": verified_token
                        }
                    }
                }
                const instance = axios.create({ headers: {'X-API-Token': token}})

                instance.patch(this.config.apiUrl + '/users/'+id ,data)
                    .then(response => {
                        let data = response.data
                        if (data && data.token) {
                            this.showFunnelSnackbar = true
                        }
                        else {
                            // Incorrect login details
                            this.sending = false
                            this.loginError = true
                        }
                    })
                    .catch(error => {
                        // Incorrect login details
                        this.sending = false
                        this.loginError = true
                        this.autoLogin = false;
                    })
            },

            async recover(){
                try {
                    
                    var urlParams = new URLSearchParams(window.location.search);

                    if(!this.recoverPassword){
                        this.errorMsg = 'Please enter a password and confirmation.'
                        return
                    }
                    if(this.recoverPassword.length <6){
                        this.errorMsg = 'Password needs to be at least 6 characters long.'
                        return
                    }

                    if(this.recoverPassword != this.recoverPasswordConfirm){
                        this.errorMsg = 'Passwords do not match.'
                        return
                    }

                    this.recoverPasswordDialogWait = true

                    var data = {
                        token: urlParams.getAll('recover')[0],
                        password: this.recoverPassword
                    }

                    let result = await this.$store.dispatch('recoverPassword', data)
                    if(result.message){
                        this.recoverPasswordDialog = false
                        this.recoverPasswordSnackbar = true
                        this.recoverPasswordDialogWait = false
                        this.$router.push({name: 'auth.login', query: {notify: 'recovered'}});
                    }else{
                        this.recoverPasswordDialogWait = false
                        this.errorMsg = 'Your request has not been successful, please contact our support team.'
                    }
                }catch (e) {
                    this.recoverPasswordDialogWait = false;
                    if(e.message && e.message === 'Request failed with status code 403') {
                        this.errorMsg = "Invalid token. Please make sure you clicked the reset link in the most recent email we sent you."
                    }else {
                        this.errorMsg = 'Error, please contact our support team.'
                    }

                    console.error('ERROR RESETTING PASSWORD', e)
                    console.error(e.message);
                }

            },
        }
    }
</script>

<style lang="scss" scoped>
    .loaderLogin{
        position: absolute;
        left:50%;
        margin-left: -36px;
        top: 310px;
        z-index: 10;
    }

    .md-dialog-title{
        margin-bottom: 0px;
    }

    .loader{
        position: absolute;
        left:50%;
        margin-left: -36px;
        top: 295px;
        z-index: 10;
    }

    .blurItem{
        filter: blur(2px);
    }

    .white{
        background-color: #fff;
    }

    .title {
        text-align: center;
    }
    .email-form{
        .forgotDialog{
            max-width: 429px;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            .sponsor-info{
                padding-top: 46px;
                text-align: center;
                font-size: 24px;
                color: #212121;
                font-family: Lato,sans-serif;
                font-weight: 300;
                position: relative;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                background-color: #fff;
                float: left;
                width: 100%;
                line-height: unset;
                height: 70px;
            }
            .errorMsg{
                position: absolute;
                color: red;
                top: 110px;
                font-size: 12px;
            }

            .title{
                padding-top: 32px;
                font-family: Lato,sans-serif;
                font-weight: 600;
                font-size: 24px;
                padding-bottom: 9px;
                display:block;
                float:left;
                width:100%;
                text-align: center;
                color: #000;
                
            }
            .intro{
                width: 95%;
                text-align: center;
                line-height: 1.75;
                font-weight: 300;
                font-size: 15px;
                -webkit-font-smoothing: initial;
                margin-left: auto;
                margin-right: auto;
                color: #595959;
                margin-bottom: 31px;
            }
            .content{
                border-radius: 5px;
                height: 220px;
                flex: unset;
                overflow: hidden;
                position: relative;
            }
            .label{
                margin-top: 9px;
                padding-left: 12px;
            }
            .submit{
                box-shadow: unset;
                border-radius: 0px;
                width: 186px;
                height: 38px;
                font-size: 17px;
            }
            .md-dialog-actions{
                padding-top: 0px;
                margin-top: 14px;
                padding-right: 0px;
                padding: 0;
                justify-content: center;
            }
            .close{
                box-shadow: unset;
                border-radius: 0px;
                width: 186px;
                height: 38px;
                font-size: 17px;
                background-color: #fff;
                color: var(--md-theme-default-primary-on-background, #0078d3);
                border: 1px solid var(--md-theme-default-primary-on-background, #0078d3);
            }
            input{
                font-size: 16px;
                line-height: 1.33333;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.9);
                position: relative;
                z-index: 1;
                height: 52px;
                padding-left: 15px;
                -webkit-box-shadow: none;
                box-shadow: none;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.6);
                outline: 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: transparent;
                max-width: 380px;
                width: 380px;
                margin-left: auto;
                margin-right: auto;
                &::placeholder{
                    color: #808080!important;
                }
                font-family: Lato,sans-serif;
            }
            .md-field:before, .md-field:after{
                bottom: unset;
                left: unset;
                right: unset;
                content: unset!important;
            }
            .md-field{
                padding-top: 0px;
                margin-bottom: 14px!important;
            }
            .md-focused .label{
                display: none;
            }
        }
    }

    .password-form{
        .forgotDialog{
            max-width: 429px;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            background-color: #fff;

            .errorMsg{
                top:160px;
                color: #d93025!important;
                font-size: 12px;
                position: absolute;
                .error-icon{
                    font-size: 16px!important;
                    color: #d93025!important;
                }
            }

            .sponsor-info{
                padding-top: 46px;
                text-align: center;
                font-size: 24px;
                color: #212121;
                font-family: Lato,sans-serif;
                font-weight: 300;
                position: relative;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                background-color: #fff;
                float: left;
                width: 100%;
                line-height: unset;
                height: 70px;
            }
            .md-dialog-content{
                flex: unset;
                padding-bottom: 20px;
            }
            .title{
                padding-top: 32px;
                font-family: Lato,sans-serif;
                font-weight: 600;
                font-size: 24px;
                padding-bottom: 9px;
                display:block;
                float:left;
                width:100%;
                text-align: center;
                color: #000;
                
            }
            .intro{
                width: 95%;
                text-align: center;
                line-height: 1.75;
                font-weight: 300;
                font-size: 15px;
                -webkit-font-smoothing: initial;
                margin-left: auto;
                margin-right: auto;
                color: #595959;
                margin-bottom: 31px;
            }
            .label{
                margin-top: 9px;
                padding-left: 12px;
            }
            .submit{
                box-shadow: unset;
                border-radius: 0px;
                width: 186px;
                height: 38px;
                font-size: 17px;
            }
            .md-dialog-actions{
                padding-top: 0px;
                margin-top: 14px;
                padding-right: 0px;
                justify-content: center;
                padding: 0;
            }
            .close{
                box-shadow: unset;
                border-radius: 0px;
                width: 186px;
                height: 38px;
                font-size: 17px;
                background-color: #fff;
                color: var(--md-theme-default-primary-on-background, #0078d3);
                border: 1px solid var(--md-theme-default-primary-on-background, #0078d3);
            }
            input{
                font-size: 16px;
                line-height: 1.33333;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.9);
                position: relative;
                z-index: 1;
                height: 52px;
                padding-left: 15px;
                -webkit-box-shadow: none;
                box-shadow: none;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.6);
                outline: 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: transparent;
                max-width: 380px;
                width: 380px;
                margin-left: auto;
                margin-right: auto;
                &::placeholder{
                    color: #808080!important;
                }
                font-family: Lato,sans-serif;
            }
          
            .md-field:before, .md-field:after{
                bottom: unset;
                left: unset;
                right: unset;
                content: unset!important;
            }
            .md-field{
                padding-top: 0px;
                margin-bottom: 14px!important;
            }
            .md-focused .label{
                display: none;
            }
            .extrapadding{
                padding-top: 16px;
            }
        }
    }

  

    @media only screen and (max-width: 430px) {
        .forgotDialog {
            max-width: 100%;
        }

        .title {
            font-size: 30px !important;
            max-width: 100%;
        }

        .password-form .forgotDialog .errorMsg{
            margin-left: 0;
            padding-left: 0px;
        }
    }

    @media only screen and (max-width: 380px) {
          .email-form .forgotDialog .errorMsg{
                top: 136px;
          }
    }
</style>